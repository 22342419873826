import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useTheme
} from '@mui/material';
import { Card, Container, Link, Text } from 'components/common';
import { ExpandMore } from '@mui/icons-material';
import { Routes } from 'consts';

const Help = () => {
  const theme = useTheme();

  return (
    <Container id="help" bgColor={theme.palette.grey[600]}>
      <Box
        position="relative"
        width="73.8rem"
        maxWidth="100%"
        m="auto"
        textAlign="center"
        py="10.8rem"
      >
        <Text
          component="h2"
          color="secondary"
          size="heading3"
          weight="bold"
          fontStyle="secondary"
          mb="0.2rem"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          Dúvidas frequentes
        </Text>
        <Text
          component="h2"
          color="secondary"
          size="heading6"
          weight="bold"
          fontStyle="secondary"
          mb="0.2rem"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          Dúvidas frequentes
        </Text>
        <Card mt="3.5rem">
          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Text size="heading" weight="bold">
                Como excluir meus dados?
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Text size="subtitle" weight="light">
                  Para excluir sua conta e dados{' '}
                  <Link text="acesse esse link" to={Routes.DELETE_ACCOUNT} />
                </Text>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Box>
    </Container>
  );
};

export default Help;
