import {
  Box,
  Container as MuiContainer,
  ContainerProps as MuiContainerProps
} from '@mui/material';
import { FOOTER_HEIGHT } from 'components/section/Footer';
import { HEADER_HEIGHT } from 'components/section/Header';

export type ContainerProps = MuiContainerProps & {
  screen?: boolean;
  bgColor?: string;
};

const Container: React.FC<ContainerProps> = ({
  sx,
  screen,
  bgColor,
  children,
  ...props
}) => (
  <Box bgcolor={bgColor}>
    <MuiContainer
      maxWidth="md"
      {...props}
      sx={{
        display: 'flex',
        minHeight: screen
          ? `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`
          : undefined,
        boxSizing: 'border-box',
        padding: screen
          ? {
              xs: '1rem 2rem 2rem',
              md: '4rem'
            }
          : {
              xs: '0 2rem',
              md: '0 4rem'
            },
        ...sx
      }}
    >
      <Box flex={1} width="100%">
        {children}
      </Box>
    </MuiContainer>
  </Box>
);

export default Container;
