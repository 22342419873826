import { Box, useTheme, BoxProps } from '@mui/material';

export type DividerProps = Omit<BoxProps, 'component'>;

const Divider: React.FC<DividerProps> = ({
  width = '100%',
  height = '0.1rem',
  bgcolor,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Box
      {...rest}
      component="hr"
      border="none"
      width={width}
      height={height}
      bgcolor={bgcolor || theme.palette.grey[500]}
    />
  );
};

export default Divider;
