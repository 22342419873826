import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  useTheme
} from '@mui/material';
import Text from '../Text';
import { ReactElement } from 'react';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
import { ThemeText, ThemeTextWeight } from 'interfaces/theme';

export type LinkProps = Omit<MuiLinkProps, 'children'> &
  Omit<HashLinkProps, 'to'> & {
    to?: string;
    text?: string;
    custom?: ReactElement;
    color?: string;
    textSize?: keyof ThemeText;
    textWeight?: keyof ThemeTextWeight;
  };

const Link: React.FC<LinkProps> = ({
  to,
  text,
  sx,
  custom,
  color,
  textSize,
  textWeight,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <MuiLink
      component={to ? HashLink : 'a'}
      to={to}
      {...rest}
      sx={{
        textDecoration: 'none',
        '&:hover span': { color: theme.palette.primary.main },
        ...sx
      }}
    >
      {text && (
        <Text
          component="span"
          size={textSize || 'subtitle2'}
          weight={textWeight || 'medium'}
          color={color}
        >
          {text}
        </Text>
      )}
      {custom}
    </MuiLink>
  );
};

export default Link;
