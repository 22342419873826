import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { SimpleDialog } from 'components/Dialogs';
import { FormSignIn } from 'components/Forms';
import {
  Button,
  Container,
  Text,
  Divider,
  InfoBanner
} from 'components/common';
import { useAuth, useUser } from 'hooks';
import type { MakeLoginDto } from 'interfaces/domain/auth';
import { SnackbarType, useSnackbar } from 'contexts/SnackbarContext';
import { ApiError, Routes } from 'consts';
import { useNavigate } from 'react-router';

const DeleteAccount = () => {
  const theme = useTheme();
  const { loginUser, isLoginLoading, deleteAuthUser, isDeleteAuthUserLoading } =
    useAuth();
  const { deleteUser, isDeleteUserLoading } = useUser();
  const { showSnackBar } = useSnackbar();
  const navigation = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const handleShowSnackBar = (message?: string) => {
    showSnackBar?.({
      message: message || 'Um erro inesperado ocorreu',
      type: SnackbarType.ERROR
    });
  };

  const handleDeleteData = async (data: MakeLoginDto) => {
    const result = await loginUser(data);
    if (result.success && result.data) {
      const deleteUserResult = await deleteUser(result.data.uid);
      if (deleteUserResult.success) {
        const deleteAuthResult = await deleteAuthUser();
        if (deleteAuthResult.success) {
          setShowDialog(false);
          setDeleteSuccess(true);
        } else {
          handleShowSnackBar();
        }
      } else {
        handleShowSnackBar();
      }
    } else {
      handleShowSnackBar(
        result.error?.message.includes(ApiError.UNATHORIZED)
          ? 'Usuário não encontrado!'
          : undefined
      );
    }
  };

  if (deleteSuccess) {
    return (
      <Container component="main" screen>
        <Box
          margin="auto"
          width="60rem"
          maxWidth="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <InfoBanner
            title="Dados excluídos"
            description={[
              'Você não tem mais acesso à sua conta.',
              'Sua conta e seus dados foram deletados com sucesso!'
            ]}
            actionText="Voltar ao início"
            onAction={() => navigation(Routes.HOME)}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" screen>
      <Text
        component="h1"
        fontStyle="secondary"
        size="heading2"
        color={theme.palette.secondary.main}
        weight="bold"
      >
        Apagar dados pessoais
      </Text>
      <Divider />
      <Box width="70rem" maxWidth="100%" m="auto" mt="5rem">
        <Text size="subtitle" mb="2rem">
          Clique no botão abaixo para apagar a sua conta do Cegonha Mogiana,
          assim como todos os seus dados pessoais armazenados pelo Cegonha
          Megiana. Você perderá todas as suas informações. Esta é uma ação
          permanente e não poderá ser desfeita. O processo será imediato.
        </Text>
        <Box textAlign="center">
          <Button
            text="Apagar dados pessoais"
            color="error"
            onClick={() => setShowDialog(true)}
          />
        </Box>
      </Box>
      <SimpleDialog
        show={showDialog}
        aria-describedby="Modal acessar conta para deletar dados."
        title="Apagar dados pessoais"
        actionText="Acessar e deletar dados"
        form="form-sign-in"
        actionLoading={
          isLoginLoading || isDeleteUserLoading || isDeleteAuthUserLoading
        }
        dangerActions
        onClose={() => setShowDialog(false)}
      >
        <Box>
          <Text size="subtitle">
            Acesse sua conta para que seus dados possam ser deletados
            permanentemente!
          </Text>
          <Box mt="3rem" mb="2rem">
            <FormSignIn onSubmit={handleDeleteData} />
          </Box>
        </Box>
      </SimpleDialog>
    </Container>
  );
};

export default DeleteAccount;
