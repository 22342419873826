import { Box } from '@mui/material';
import { Container, Text } from 'components/common';
import { ReactComponent as MortalityRateGraph } from 'assets/images/mortality-rate-graph.svg';
import { ReactComponent as PreventableDeathsGraph } from 'assets/images/preventable-deaths-graph.svg';

const Statistic = () => (
  <Container id="statistic">
    <Box
      position="relative"
      width="100rem"
      maxWidth="100%"
      m="auto"
      textAlign="center"
      py="10.8rem"
    >
      <Text
        component="h2"
        color="primary"
        size="heading3"
        weight="bold"
        fontStyle="secondary"
        mb="0.2rem"
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        Estatísticas
      </Text>
      <Text
        component="h2"
        color="primary"
        size="heading6"
        weight="bold"
        fontStyle="secondary"
        mb="0.2rem"
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        Estatísticas
      </Text>
      <Box
        display="flex"
        justifyContent="space-between"
        mt="7.5rem"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '3rem', md: '1rem' }
        }}
      >
        <Box textAlign="center">
          <Text size="heading2" weight="light" mb="1.4rem">
            Taxa de mortalidade
          </Text>
          <MortalityRateGraph width="100%" />
        </Box>
        <Box textAlign="center">
          <Text size="heading2" weight="light" mb="1.4rem">
            % de mortes evitáveis
          </Text>
          <PreventableDeathsGraph width="100%" />
        </Box>
      </Box>
    </Box>
  </Container>
);

export default Statistic;
