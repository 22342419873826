import { getAuth, signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { LoginDto, MakeLoginDto } from 'interfaces/domain/auth';
import { ResponseDto } from 'interfaces/api';
import app from 'shared/firebase';

export class AuthService {
  private auth = getAuth(app);

  constructor() {
    this.authUser = this.authUser.bind(this);
  }

  async authUser(data: MakeLoginDto): Promise<ResponseDto<LoginDto>> {
    return await signInWithEmailAndPassword(
      this.auth,
      data.email,
      data.password
    )
      .then((result) => ({
        success: true,
        data: {
          email: result.user.email,
          uid: result.user.uid
        } as unknown as LoginDto
      }))
      .catch((error) => ({ success: false, error }));
  }

  async deleteAuthUser(): Promise<ResponseDto<undefined>> {
    const user = this.auth.currentUser;
    if (user) {
      return await deleteUser(user)
        .then(() => ({
          success: true
        }))
        .catch((error) => ({ success: false, error }));
    }

    return {
      success: false,
      error: new Error('User not authenticated!')
    };
  }
}
