import { doc, deleteDoc, getFirestore } from 'firebase/firestore';
import { ResponseDto } from 'interfaces/api';
import app from 'shared/firebase';

export class UserService {
  private firestore = getFirestore(app);

  constructor() {
    this.deleteUser = this.deleteUser.bind(this);
  }

  async deleteUser(id: string): Promise<ResponseDto<undefined>> {
    return await deleteDoc(doc(this.firestore, 'patient', id))
      .then(() => ({
        success: true
      }))
      .catch((error) => ({ success: false, error }));
  }
}
