import { Box, useTheme } from '@mui/material';
import { ApplicationStoreButton, Container, Text } from 'components/common';

const Intro = () => {
  const theme = useTheme();

  return (
    <Container id="intro">
      <Box width="41rem" maxWidth="100%" m="auto" pt="10rem">
        <Text
          component="h1"
          size="heading3"
          fontStyle="secondary"
          weight="bold"
          align="center"
          color="primary"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          Para mamães <br />
          <Text
            component="span"
            size="heading3"
            fontStyle="secondary"
            weight="bold"
            align="center"
            color="secondary"
          >
            e bebês
          </Text>
        </Text>

        <Text
          component="h1"
          size="heading6"
          fontStyle="secondary"
          weight="bold"
          align="center"
          color="primary"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          Para mamães{' '}
          <Text
            component="span"
            size="heading6"
            fontStyle="secondary"
            weight="bold"
            align="center"
            color="secondary"
          >
            e bebês
          </Text>
        </Text>
        <Box
          display="flex"
          mt="2.6rem"
          mb="1rem"
          justifyContent="center"
          alignItems="center"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '1.8rem', sm: '3.2rem' }
          }}
        >
          <ApplicationStoreButton type="app-store" />
          <ApplicationStoreButton type="google-play" />
        </Box>
      </Box>
      <Box>
        <Box position="relative">
          <Box
            position="absolute"
            bottom="-2rem"
            zIndex={2}
            sx={{
              display: { xs: 'none', md: 'block' },
              left: { md: '-6rem', lg: '-10rem' }
            }}
          >
            <Box
              component="img"
              src="/assets/images/logo-side.png"
              alt="Logo de lado"
            />
          </Box>
          <Box position="relative" overflow="hidden">
            <Box
              position="absolute"
              width="73.32rem"
              maxWidth="100%"
              sx={{ aspectRatio: 1 }}
              bgcolor={theme.palette.primary.main}
              left={0}
              right={0}
              ml="auto"
              mr="auto"
              top="10rem"
              borderRadius="100%"
            />

            <Box position="relative" textAlign="center" zIndex={1} top="0.5rem">
              <Box
                component="img"
                maxWidth="100%"
                src="/assets/images/app-phone-maketing.png"
                alt="Imagens do aplicativo em smarthphones"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Intro;
