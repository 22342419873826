import { Box } from '@mui/material';
import { InputText } from 'components/common';
import { useForm } from 'react-hook-form';
import Zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import type { MakeLoginDto } from 'interfaces/domain/auth';

export type FormSignInProps = {
  onSubmit: (data: MakeLoginDto) => void;
};

const FormSchema = Zod.object({
  email: Zod.string().min(1, 'Campo obrigatório').email('E-mail imválido'),
  password: Zod.string().min(1, 'Campo obrigatório')
});

type FormSchemaProps = Zod.infer<typeof FormSchema>;

const FormSignIn: React.FC<FormSignInProps> = ({ onSubmit }) => {
  const {
    handleSubmit: formSubmit,
    formState: { errors },
    register
  } = useForm<FormSchemaProps>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: zodResolver(FormSchema),
    mode: 'onBlur'
  });

  return (
    <Box
      id="form-sign-in"
      component="form"
      display="flex"
      flexDirection="column"
      gap="2rem"
      onSubmit={formSubmit(onSubmit)}
    >
      <InputText
        label="E-mail"
        fullWidth
        errorMessage={errors.email?.message}
        inputProps={register('email')}
      />
      <InputText
        label="Senha"
        fullWidth
        type="password"
        errorMessage={errors.password?.message}
        inputProps={register('password')}
      />
    </Box>
  );
};

export default FormSignIn;
