import { Box, BoxProps } from '@mui/material';

export type CardProps = BoxProps;

const Card: React.FC<CardProps> = ({
  p = '2rem',
  bgcolor = '#FFFFFF',
  borderRadius = '3.2rem',
  boxShadow = 'box-shadow: 0px 4px 44px 0px #00000026',
  ...props
}) => (
  <Box
    p={p}
    bgcolor={bgcolor}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    {...props}
  />
);

export default Card;
