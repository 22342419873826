import { Box } from '@mui/material';
import { Intro, Mission, Statistic, Help } from './sections';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace(/#/g, ''));
      window.scroll({
        top: (element?.offsetTop || 0) + 100,
        behavior: 'smooth'
      });
    }
  }, []);

  return (
    <Box component="main">
      <Intro />
      <Mission />
      <Statistic />
      <Help />
    </Box>
  );
};

export default Home;
