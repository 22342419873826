import { Typography, TypographyProps, useTheme } from '@mui/material';
import { ThemeText, ThemeTextWeight } from 'interfaces/theme';

export type TextProps = Omit<
  TypographyProps,
  'fontSize' | 'lineHeight' | 'fontWeight' | 'textAlign'
> & {
  fontStyle?: 'primary' | 'secondary';
  size?: keyof ThemeText;
  weight?: keyof ThemeTextWeight;
  align?: 'center' | 'justify' | 'left' | 'right';
};

const Text: React.FC<TextProps> = ({
  children,
  fontStyle = 'primary',
  size,
  weight,
  align,
  color,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Typography
      fontFamily={
        fontStyle === 'primary'
          ? 'Open Sans, sans-serif'
          : 'Quicksand, sans-serif'
      }
      fontSize={theme.text[size || 'body'].size}
      lineHeight={theme.text[size || 'body'].lineHeight}
      fontWeight={theme.textWeight[weight || 'regular']}
      textAlign={align}
      color={color || theme.palette.grey[700]}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Text;
