import {
  Box,
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled
} from '@mui/material';
import Text from '../Text';

export type ButtonProps = Omit<MuiButtonProps, 'children'> & {
  textColor?: string;
  text?: string;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  color = 'primary',
  textColor = '#FFFFFF',
  text,
  loading,
  ...props
}) => (
  <StyledTextField {...props} variant={variant} color={color}>
    {loading && (
      <CircularProgress
        color="inherit"
        size="2rem"
        sx={{ position: 'absolute' }}
      />
    )}
    <Box sx={{ opacity: loading ? 0 : 1 }}>
      <Text weight="medium" color={variant === 'outlined' ? color : textColor}>
        {text}
      </Text>
    </Box>
  </StyledTextField>
);

export default Button;

const StyledTextField = styled(MuiButton)(({ theme }) => ({
  boxShadow: 'none',
  minWidth: '10rem',
  fontSize: '1.6rem',
  borderRadius: theme.borderRadius.main,
  overflow: 'hidden',
  padding: '1rem 2rem',
  '&:hover': {
    boxShadow: 'none'
  }
}));
