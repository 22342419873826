import { TextField, TextFieldProps, styled } from '@mui/material';

export type InputTextProps = Omit<TextFieldProps, 'error'> & {
  errorMessage?: string;
};

const InputText: React.FC<InputTextProps> = ({
  errorMessage,
  helperText,
  ...props
}) => (
  <StyledTextField
    error={!!errorMessage}
    helperText={errorMessage || helperText}
    {...props}
  />
);

export default InputText;

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.borderRadius.main,
    '& fieldset': {
      '& legend': {
        display: 'none'
      },
      top: 0
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    },
    padding: 0,
    '& textarea': {
      padding: '0.8rem',
      fontSize: '1.6rem',
      minHeight: '6rem'
    },
    '& input': {
      padding: '0.8rem',
      fontSize: '1.6rem'
    }
  },
  '& .MuiFormLabel-root': {
    transform: 'translate(0px, -24px) scale(1)',
    fontSize: '1.6rem',
    fontWeight: 700,
    '&.Mui-focused': {
      color: theme.palette.secondary.main
    }
  },
  marginTop: '2rem',
  '& .MuiFormHelperText-root': {
    marginLeft: '0.1rem'
  }
}));
