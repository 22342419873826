'use client';

import React, {
  PropsWithChildren,
  useContext,
  useState,
  createContext
} from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

export enum SnackbarType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export interface SnackbarContextType {
  showSnackBar?: (data: { message: string; type?: SnackbarType }) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>({});

const snackDefaultValue = {
  show: false,
  message: '',
  type: SnackbarType.INFO
};

const snackSeverity = {
  [SnackbarType.SUCCESS]: 'success',
  [SnackbarType.ERROR]: 'error',
  [SnackbarType.WARNING]: 'warning',
  [SnackbarType.INFO]: 'info'
};

export const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [snackData, setSnackData] = useState<{
    show: boolean;
    message: string;
    type: SnackbarType;
  }>(snackDefaultValue);

  const showSnackBar = (data: { message: string; type?: SnackbarType }) => {
    setSnackData({
      show: true,
      message: data.message,
      type: data.type || SnackbarType.INFO
    });
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackBar
      }}
    >
      <>
        <Snackbar
          open={snackData.show}
          autoHideDuration={6000}
          onClose={() => setSnackData(snackDefaultValue)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackData(snackDefaultValue)}
            severity={snackSeverity[snackData.type] as AlertColor}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackData.message}
          </Alert>
        </Snackbar>
        {children}
      </>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
