import { Box, useTheme } from '@mui/material';
import { Card, Container, Text } from 'components/common';
import { ReactComponent as Bubbles } from 'assets/images/bubbles.svg';

const Mission = () => {
  const theme = useTheme();

  return (
    <Container id="mission" bgColor={theme.palette.secondary.main}>
      <Box
        position="relative"
        width="53.5rem"
        maxWidth="100%"
        m="auto"
        py="14.4rem"
      >
        <Box position="absolute" zIndex={0} top="8rem" left="-6.7rem">
          <Bubbles />
        </Box>
        <Card
          position="relative"
          zIndex={1}
          borderRadius="3.2rem 3.2rem 3.2rem 0"
          p="4rem"
        >
          <Text
            component="h2"
            color="primary"
            size="heading3"
            weight="bold"
            fontStyle="secondary"
            mb="0.2rem"
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            Nossa missão
          </Text>
          <Text
            component="h2"
            color="primary"
            size="heading6"
            weight="bold"
            fontStyle="secondary"
            mb="0.2rem"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            Nossa missão
          </Text>
          <Text size="heading2" weight="light" mb="1.4rem">
            Somos a B-ONE
          </Text>
          <Text size="subtitle" weight="light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eros
            nisi, luctus id velit vitae, commodo eleifend libero. Nunc nisl
            augue, consequat vitae dolor ut, efficitur porta tortor. Nullam
            vulputate convallis turpis facilisis sollicitudin. Interdum et
            malesuada fames ac ante ipsum primis in faucibus. Nulla semper nec
            diam at euismod. Ut accumsan augue at quam pulvinar.
          </Text>
        </Card>
      </Box>
    </Container>
  );
};

export default Mission;
