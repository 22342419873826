import { Box, useTheme } from '@mui/material';
import { Link, Text } from 'components/common';
import { Routes } from 'consts';
import { ReactComponent as IconInstagram } from 'assets/images/instagram.svg';
import { ReactComponent as IconLinkedin } from 'assets/images/linkedin.svg';

export const FOOTER_HEIGHT = '25.9rem';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box height={FOOTER_HEIGHT} bgcolor={theme.palette.grey[700]} px="2rem">
      <Box
        width="73.8rem"
        maxWidth="100%"
        m="auto"
        py="4.2rem"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap="3rem"
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="0.8rem"
            sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }}
          >
            <Link
              text="Políticas de privacidade"
              color="#FFFFFF"
              to={Routes.PRIVACY_POLICIES}
              textSize="subtitle"
              textWeight="light"
            />
            <Link
              text="cegonhamogiana@gmail.com"
              color="#FFFFFF"
              href="mailto:cegonhamogiana@gmail.com"
              sx={{ opacity: 0.4 }}
              textSize="subtitle"
              textWeight="light"
            />
          </Box>
          <Box
            display="flex"
            gap="1.2rem"
            sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
          >
            <Link custom={<IconLinkedin />} href="#" />
            <Link custom={<IconInstagram />} href="#" />
          </Box>
        </Box>
        <Text size="subtitle" weight="light" color="#FFFFFF" align="center">
          Desenvolvido por B-ONE SAÚDE
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
