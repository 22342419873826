import { ReactComponent as AppleLogo } from 'assets/images/apple-logo.svg';
import { ReactComponent as GooglePlayLogo } from 'assets/images/google-play-logo.svg';
import Text from '../Text';
import { Box, Link } from '@mui/material';

export type ApplicationStoreButtonProps = {
  type: 'app-store' | 'google-play';
};

const ApplicationStoreButton: React.FC<ApplicationStoreButtonProps> = ({
  type
}) => {
  const isAppStore = type === 'app-store';

  return (
    <Link href="#" sx={{ textDecoration: 'none' }}>
      <Box
        width="16.8rem"
        height="6.4rem"
        border={2}
        borderColor="#D9D7D7"
        borderRadius="1.2rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1.2rem"
        px={isAppStore ? '2rem' : '1.4rem'}
      >
        <div>{isAppStore ? <AppleLogo /> : <GooglePlayLogo />}</div>
        <Box>
          <Text component="span" size="caption" weight="medium">
            {isAppStore ? 'Baixe na' : 'Disponível no'}{' '}
            <Text component="span" size="subtitle" weight="medium">
              {isAppStore ? 'App Store' : 'Google Play'}
            </Text>
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

export default ApplicationStoreButton;
