import { Box, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'contexts/SnackbarContext';
import { Header, Footer } from 'components/section';

// Theme
import theme from 'shared/theme/material-theme';

// Routes
import { mainRouter } from 'routes';

const App = () => {
  return (
    <Box className="App" minHeight="100vh">
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <RouterProvider
            router={mainRouter({ header: <Header />, footer: <Footer /> })}
          />
        </SnackbarProvider>
      </ThemeProvider>
    </Box>
  );
};

export default App;
