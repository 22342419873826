import { Box, useTheme } from '@mui/material';
import { Container, Divider, Text } from 'components/common';
import { useEffect } from 'react';

const PrivacyPolicies = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container
      component="main"
      screen
      sx={{ width: '88rem', maxWidth: '100%' }}
    >
      <Box pb="5rem">
        <Text
          component="h1"
          size="heading2"
          weight="medium"
          color={theme.palette.secondary.main}
          fontStyle="secondary"
        >
          Política de Privacidade e Proteção dos dados
        </Text>
        <Divider mb="5rem" />
        <Text size="body" align="justify" mb="2rem">
          A sua privacidade é importante para nós!
        </Text>
        <Text size="body" align="justify" mb="2rem">
          É política da Cegonha Mogiana respeitar a sua privacidade em relação a
          qualquer informação sua que possamos coletar em nosso site., ou outros
          que possuímos e operamos.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço, como por exemplo uma simulação de
          preços. Fazemo-lo por meios justos e legais, com o seu conhecimento e
          consentimento. Também informamos por que estamos coletando e como será
          usado.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Apenas retemos as informações essenciais para as finalidades que se
          destinam, as quais são coletadas pelo tempo necessário para fornecer o
          serviço solicitado.{' '}
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Quando armazenamos dados, protegemos dentro de meios comercialmente
          aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação,
          cópia, uso ou modificação não autorizados.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Você é livre para recusar a nossa solicitação de informações pessoais
          desde que as informações não sejam essenciais para a prestação dos
          serviços que oferecemos, caso contrário torna-se inviável a relação
          contratual de prestação de serviços de plano de saúde sem a coleta de
          dados essenciais.
        </Text>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Dados que coletamos
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Para prestarmos os nossos serviços, coletamos as seguintes
          informações:
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Beneficiários: Coletamos informações pessoais essenciais para
          prestação de serviços de CRM, a fim de viabilizar os atendimentos
          contratados, e respectivas autorizações que darão acesso à utilização
          do plano de saúde e atendimento clínico, bem como para fins
          administrativos relacionados aos serviços prestados, como por exemplo
          o envio de informações exigidas pela Agência Nacional de Saúde
          Suplementar. Essas informações incluem dados cadastrais, como nome,
          CPF, endereço, data de nascimento, gênero, nome da mãe, informações
          para contato, declaração geral de saúde, CNS – Cartão Nacional de
          Saúde, endereço eletrônico, SIS - Sistema Integrado de Saúde Mogiana,
          DUM - Data da Última Mestruação. Via de regra, a coleta é feita
          diretamente dos beneficiários quando ocorrer o cadastro no app, e nos
          contratos coletivos empresariais ou por adesão, a coleta poderá ser
          realizada diretamente das entidades contratantes.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Rede de Apoio: coletamos informações de apoiadores dos beneficiários,
          semelhante aos dados de cadastro dos titulares. Nós coletaremos
          somente as informações necessárias para as atividades primárias de
          prestação de serviços de plano de saúde.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Navegação, Registros de Acesso e demais informações automatizadas:
          Algumas informações são automaticamente armazenadas, como por exemplo:
          quando há acesso dos aplicativos, mídias sociais, Internet Protocol
          (IP), tipo de navegador, páginas visualizadas, termos de busca,
          informações específicas de dispositivos móveis que acessam o site e/ou
          aplicativo e localização geográfica são alguns exemplos desta coleta.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          As datas e horas das interações do usuário como site, aplicativo e
          sites de terceiros são também armazenadas.
        </Text>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Como usamos os seus dados
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Poderemos utilizar os dados, ainda, para:
        </Text>
        <Box component="ol" type="a" px="1.5rem">
          <Text component="li" size="body" align="justify" mb="2rem">
            Estabelecer comunicações, (telefone, e-mail, aplicativos de
            comunicação whatsapp), para mantê-lo informado sobre nossos
            serviços, promoções, envio de boletos, e outros;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Gerenciar comunicações; analisar nossos serviços, sites, aplicativos
            móveis e quais quer outros recursos digitais de modo a facilitar sua
            funcionalidade; contabilidade, auditoria, faturamento;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Anonimizar os dados pessoais coletados, e elaborar e fornecer
            relatórios de dados agregados com informações anonimizadas
            (incluindo compilações, análises, regrase modelos analíticos e
            preditivos, e outros relatórios agregados);
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Cumprir obrigações legais ou regulatórias;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Exercer direitos judiciais e administrativos;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Para outras finalidades autorizadas ou exigidas por lei.
          </Text>
        </Box>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Como quem compartilharemos os dados
        </Text>
        <Box component="ol" type="a" px="1.5rem">
          <Text component="li" size="body" align="justify" mb="2rem">
            Com empresas do mesmo grupo da Cegonha Mogiana, com as quais desde
            já se compromete a manter os mesmos compromissos com a proteção de
            dados;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Com prestadores de serviços terceirizados aos quais exigimos que
            eles utilizem seus dados de acordo com nossas instruções e termos ou
            mediante seu consentimento expresso, quando aplicável;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Com autoridades, entidades governamentais ou terceiros para defesa
            dos interesses da Cegonha Mogiana;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Mediante ordem judicial, ou atender a pedido de autoridade
            administrativa que detenha poder para solicitação.
          </Text>
        </Box>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Cookies
        </Text>
        <Text size="body" align="justify" mb="2rem">
          O que são cookies?
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Como é prática comum em quase todos os sites profissionais, este site
          usa cookies, que são pequenos arquivos baixados no seu computador,
          para melhorar sua experiência. Esta página descreve quais informações
          eles coletam, como as usamos e por que às vezes precisamos armazenar
          esses cookies. Também compartilharemos como você pode impedir que
          esses cookies sejam armazenados, no entanto, isso pode fazer o
          downgrade ou ‘quebrar’ certos elementos da funcionalidade do site.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Como usamos os cookies?
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Utilizamos cookies por vários motivos, detalhados abaixo.
          Infelizmente, na maioria dos casos, não existem opções padrão do setor
          para desativar os cookies sem desativar completamente a funcionalidade
          e os recursos que eles adicionam a este site. É recomendável que você
          deixe todos os cookies se não tiver certeza se precisa ou não deles,
          caso sejam usados ​​para fornecer um serviço que você usa.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Desativar cookies
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Você pode impedir a configuração de cookies ajustando as configurações
          do seu navegador (consulte a Ajuda do navegador para saber como fazer
          isso). Esteja ciente de que a desativação de cookies afetará a
          funcionalidade deste e de muitos outros sites que você visita. A
          desativação de cookies geralmente resultará na desativação de
          determinadas funcionalidades e recursos deste site. Portanto, é
          recomendável que você não desative os cookies.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Cookies que definimos
        </Text>
        <Box pl="2rem" mb="2rem">
          <Text size="body" align="justify">
            Cookies relacionados à conta se você criar uma conta conosco,
            usaremos cookies para o gerenciamento do processo de inscrição e
            administração geral. Esses cookies geralmente serão excluídos quando
            você sair do sistema, porém, em alguns casos, eles poderão
            permanecer posteriormente para lembrar as preferências do seu site
            ao sair. Cookies relacionados ao login utilizamos cookies quando
            você está logado, para que possamos lembrar dessa ação. Isso evita
            que você precise fazer login sempre que visitar uma nova página.
            Esses cookies são normalmente removidos ou limpos quando você efetua
            logout para garantir que você possa acessar apenas a recursos e
            áreas restritas ao efetuar login.
          </Text>
          <Text size="body" align="justify">
            Cookies relacionados a boletins por e-mail este site oferece
            serviços de assinatura de boletim informativo ou e-mail e os cookies
            podem ser usados ​​para lembrar se você já está registrado e se deve
            mostrar determinadas notificações válidas apenas para usuários
            inscritos / não inscritos.
          </Text>
          <Text size="body" align="justify">
            Pedidos processando cookies relacionados este site oferece
            facilidades de comércio eletrônico ou pagamento e alguns cookies são
            essenciais para garantir que seu pedido seja lembrado entre as
            páginas, para que possamos processá-lo adequadamente. Cookies
            relacionados a pesquisas periodicamente, oferecemos pesquisas e
            questionários para fornecer informações interessantes, ferramentas
            úteis ou para entender nossa base de usuários com mais precisão.
            Essas pesquisas podem usar cookies para lembrar quem já participou
            numa pesquisa ou para fornecer resultados precisos após a alteração
            das páginas.
          </Text>
          <Text size="body" align="justify">
            Cookies relacionados a formulários quando você envia dados por meio
            de um formulário como os encontrados nas páginas de contacto ou nos
            formulários de comentários, os cookies podem ser configurados para
            lembrar os detalhes do usuário para correspondência futura.
          </Text>
          <Text size="body" align="justify">
            Cookies de preferências do site Para proporcionar uma ótima
            experiência neste site, fornecemos a funcionalidade para definir
            suas preferências de como esse site é executado quando você o usa.
            Para lembrar suas preferências, precisamos definir cookies para que
            essas informações possam ser chamadas sempre que você interagir com
            uma página for afetada por suas preferências.
          </Text>
        </Box>
        <Text size="body" align="justify" mb="2rem">
          Cookies de Terceiros
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Em alguns casos especiais, também usamos cookies fornecidos por
          terceiros confiáveis. A seção a seguir detalha quais cookies de
          terceiros você pode encontrar através deste site.
        </Text>
        <Box pl="2rem" mb="2rem">
          <Text size="body" align="justify">
            Este site usa o Google Analytics, que é uma das soluções de análise
            mais difundidas e confiáveis ​​da Web, para nos ajudar a entender
            como você usa o site e como podemos melhorar sua experiência. Esses
            cookies podem rastrear itens como quanto tempo você gasta no site e
            as páginas visitadas, para que possamos continuar produzindo
            conteúdo atraente.
          </Text>
        </Box>
        <Text size="body" align="justify" mb="2rem">
          Para mais informações sobre cookies do Google Analytics, consulte a
          página oficial do Google Analytics.
        </Text>
        <Box pl="2rem" mb="2rem">
          <Text size="body" align="justify">
            As análises de terceiros são usadas para rastrear e medir o uso
            deste site, para que possamos continuar produzindo conteúdo
            atrativo. Esses cookies podem rastrear itens como o tempo que você
            passa no site ou as páginas visitadas, o que nos ajuda a entender
            como podemos melhorar o site para você.
          </Text>
          <Text size="body" align="justify">
            Periodicamente, testamos novos recursos e fazemos alterações subtis
            na maneira como o site se apresenta. Quando ainda estamos testando
            novos recursos, esses cookies podem ser usados ​​para garantir que
            você receba uma experiência consistente enquanto estiver no site,
            enquanto entendemos quais otimizações os nossos usuários mais
            apreciam.
          </Text>
          <Text size="body" align="justify">
            À medida que vendemos produtos, é importante entendermos as
            estatísticas sobre quantos visitantes de nosso site realmente
            compram e, portanto, esse é o tipo de dados que esses cookies
            rastrearão. Isso é importante para você, pois significa que podemos
            fazer previsões de negócios com precisão que nos permitem analisar
            nossos custos de publicidade e produtos para garantir o melhor preço
            possível.
          </Text>
        </Box>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Como mantemos os dados seguros
        </Text>
        <Box component="ol" type="a" px="1.5rem">
          <Text component="li" size="body" align="justify" mb="2rem">
            Criptografia e anonimização de dados;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Proteção contra acesso não autorizado a seus sistemas;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Controle e registro de todas as pessoas que acessam cada local onde
            armazenamos dados pessoais;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Medidas institucionais como governança em privacidade atualizadas;
            considerando que mesmo adotando as providências necessárias, nenhuma
            transmissão de dados é totalmente segura, a Cegonha Mogiana tem
            medidas pessoais de proteção dentro do seu próprio ambiente.
          </Text>
        </Box>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Retenção das informações coletadas
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Os dados pessoais tratados pela Cegonha Mogiana poderão ser eliminados
          quando deixarem de ser úteis para os fins para os quais foram
          coletados, ou quando o Titular solicitar sua exclusão, exceto nos
          casos em que a manutenção dos dados for autorizada por lei ou
          regulação aplicável, como por exemplo, a guarda de prontuários médicos
          pelo prazo legal, ou ainda informações financeiras reguladas pela
          Receita Federal, como também em situações onde é necessária a guarda
          para futuras ações judiciais, reclamações perante órgãos públicos,
          agências reguladoras ou administrativas em que precisem comprovar o
          cumprimento de obrigações legais ou contratuais.
        </Text>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Seus direitos
        </Text>
        <Box component="ol" type="a" px="1.5rem">
          <Text component="li" size="body" align="justify" mb="2rem">
            Confirmação de tratamento de dados pessoais e acesso aos dados
            através de requisição aos seus dados armazenados;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            A qualquer momento o Titular poderá solicitar alteração de dados
            incompletos, inexatos ou desatualizados através das centrais de
            atendimento ao cliente;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Portabilidade de seus dados pessoais mediante requisição específica,
            desde que atenda aos termos da legislação aplicável;
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Eliminação dos dados pessoais, observando-se as condições expostas
            no item “6” desta Política; ​
          </Text>
          <Text component="li" size="body" align="justify" mb="2rem">
            Informações sobre uso compartilhado de dados​.
          </Text>
          {/* <Text component="li" size="body" align="justify" mb="2rem"></Text> */}
        </Box>
        <Text component="h2" size="heading" weight="medium" mt="5rem" mb="2rem">
          Alterações desta política, legislação e foro
        </Text>
        <Text size="body" align="justify" mb="2rem">
          A Cegonha Mogiana se reserva no direito de alterar a qualquer momento
          a presente política de privacidade, sempre em observância à legislação
          brasileira em vigor aplicável aos temas relacionados tanto à proteção
          de dados quanto à sua guarda, que, em se tratando de questões
          relacionadas à saúde, estão sujeitas à legislações específicas, bem
          como normas do Conselho Regional de Medicina.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Para ter acesso ao controlado e ao encarregado dos dados pessoais, ou
          esclarecer suas dúvidas, entre em contato com
          cegonhamogiana@gmail.com.
        </Text>
        <Text size="body" align="justify" mb="2rem">
          Equipe Cegonha Mogiana
        </Text>
        <Text size="body">Atualização: 22 de Maio de 2024.</Text>
      </Box>
    </Container>
  );
};

export default PrivacyPolicies;
