import { MakeLoginDto } from 'interfaces/domain/auth';
import { AuthService } from 'services';
import { useState } from 'react';

const useAuth = () => {
  const authService = new AuthService();

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isDeleteAuthUserLoading, setIsDeleteAuthUserLoading] = useState(false);

  const loginUser = async (data: MakeLoginDto) => {
    setIsLoginLoading(true);
    const result = await authService.authUser(data);

    setIsLoginLoading(false);
    return result;
  };

  const deleteAuthUser = async () => {
    setIsDeleteAuthUserLoading(true);
    const result = await authService.deleteAuthUser();

    setIsDeleteAuthUserLoading(false);
    return result;
  };

  return {
    loginUser,
    isLoginLoading,
    deleteAuthUser,
    isDeleteAuthUserLoading
  };
};

export default useAuth;
