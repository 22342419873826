import { Box } from '@mui/material';
import Text from '../Text';
import Button from '../Button';

export type InfoBannerProps = {
  title: string;
  description: string[];
  actionText?: string;
  onAction?: () => void;
};

const InfoBanner: React.FC<InfoBannerProps> = ({
  title,
  description,
  actionText,
  onAction
}) => (
  <Box textAlign="center">
    <Text size="heading3" color="secondary" weight="bold" mb="2rem">
      {title}
    </Text>
    <Box mb="3rem">
      {description.map((item, index) => (
        <Text key={`text-${index}`} size="subtitle" weight="medium">
          {item}
        </Text>
      ))}
    </Box>
    <Button text={actionText} onClick={onAction} />
  </Box>
);

export default InfoBanner;
