import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme
} from '@mui/material';
import { Button, Text } from 'components/common';
import { PropsWithChildren } from 'react';

export type SimpleDialogProps = PropsWithChildren & {
  show: boolean;
  'aria-describedby': string;
  title: string;
  hideActions?: boolean;
  actionText?: string;
  cancelText?: string;
  dangerActions?: boolean;
  actionLoading?: boolean;
  form?: string;
  onClose: () => void;
  onAction?: () => void;
};

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  show,
  title,
  'aria-describedby': ariaDescribedby,
  children,
  hideActions,
  cancelText,
  actionText,
  dangerActions,
  actionLoading,
  form,
  onClose,
  onAction
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={show}
      aria-describedby={ariaDescribedby}
      sx={{ '& .MuiPaper-root': { borderRadius: theme.borderRadius.main } }}
      onClose={onClose}
    >
      <Box pt="2.4rem" px="2.4rem">
        <Text
          component="h2"
          size="heading"
          weight="bold"
          fontStyle="secondary"
          color={theme.palette.secondary.main}
        >
          {title}
        </Text>
      </Box>
      <DialogContent>{children}</DialogContent>
      {!hideActions && (
        <DialogActions>
          <Box
            display="flex"
            flex={1}
            gap="2rem"
            px="1.6rem"
            pb="2rem"
            sx={{
              justifyContent: { xs: 'space-between', sm: 'flex-end' },
              flexDirection: { xs: 'column-reverse', sm: 'row' }
            }}
          >
            <Button
              text={cancelText || 'Cancelar'}
              variant="text"
              color="secondary"
              textColor={theme.palette.grey[700]}
              disabled={actionLoading}
              onClick={onClose}
            />
            <Button
              type={form ? 'submit' : 'button'}
              form={form}
              text={actionText || 'OK'}
              color={dangerActions ? 'error' : 'secondary'}
              disabled={actionLoading}
              loading={actionLoading}
              onClick={onAction}
            />
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SimpleDialog;
