import { UserService } from 'services';
import { useState } from 'react';

const useUser = () => {
  const userService = new UserService();

  const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);

  const deleteUser = async (id: string) => {
    setIsDeleteUserLoading(true);
    const result = await userService.deleteUser(id);
    setIsDeleteUserLoading(false);
    return result;
  };

  return {
    deleteUser,
    isDeleteUserLoading
  };
};

export default useUser;
