'use client';
import { createTheme } from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary'];
    white: Palette['primary'];
  }
  interface PaletteOptions {
    default: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
  }

  interface Duration {
    slow: number;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    default: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    linkText: true;
  }
}

// Define your theme
const theme = createTheme(
  {
    palette: {
      mode: 'light',
      background: {
        default: '#ffffff'
      },
      primary: {
        main: '#F09A4B',
        light: '#F6B07B',
        dark: '#D77F42'
      },
      secondary: {
        main: '#26BACE',
        light: '#80D7E1',
        dark: '#008998'
      },
      success: {
        main: '#26BF00'
      },
      error: {
        main: '#CF3B37'
      },
      default: {
        main: '#0F0F0F'
      },
      white: {
        main: '#fff'
      },
      grey: {
        '700': '#6E6E6E',
        '600': '#F5F5F5',
        '500': '#DFDFDF',
        '300': '#F1F5F7',
        '200': '#FCFCFC',
        '100': '#FAFAFA'
      }
    },
    borderRadius: {
      main: '1.2rem'
    },
    typography: {
      fontSize: 26.134
    },
    text: {
      heading6: {
        size: '6rem',
        lineHeight: '6.72rem'
      },
      heading3: {
        size: '3rem',
        lineHeight: '4rem'
      },
      heading2: {
        size: '2.4rem',
        lineHeight: '3.268rem'
      },
      heading: {
        size: '2rem',
        lineHeight: '2.724rem'
      },
      subtitle2: {
        size: '1.8rem',
        lineHeight: '2.379rem'
      },
      subtitle: {
        size: '1.6rem',
        lineHeight: '2.179rem'
      },
      body: {
        size: '1.4rem',
        lineHeight: '1.907rem'
      },
      caption: {
        size: '1.2rem',
        lineHeight: '1.634rem'
      }
    },
    textWeight: {
      extraBold: 800,
      bold: 700,
      medium: 600,
      regular: 500,
      light: 400
    },
    shape: {
      borderRadius: 1
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '27px',
            textTransform: 'none'
          }
        },
        variants: [
          {
            props: {
              variant: 'linkText'
            },
            style: {
              color: '#007DBF',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }
        ]
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '1.4rem',
            textTransform: 'unset'
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTabs-indicator': {
              height: '4px'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#5F5F5F'
          }
        }
      }
    },
    transitions: {
      duration: {
        slow: 600
      }
    }
  },
  corePtBR
);

export default theme;
