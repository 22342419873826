import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Container, Link } from 'components/common';
import { ReactComponent as Logo } from 'assets/images/logo-text.svg';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useState } from 'react';
import { Routes } from 'consts';

export const HEADER_HEIGHT = '7.7rem';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Container component="header">
      <Box
        height={HEADER_HEIGHT}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="Menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon color="inherit" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' }
            }}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.text} onClick={handleCloseNavMenu}>
                <Link key={item.text} text={item.text} to={item.to} />
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Link custom={<Logo />} to={Routes.HOME} />
        <Box
          sx={{ display: { xs: 'block', md: 'none' } }}
          width="5.2rem"
          height="5.2rem"
        />
        <Box
          component="menu"
          gap="4.8rem"
          sx={{ listStyleType: 'none', display: { xs: 'none', md: 'flex' } }}
        >
          {menuItems.map((item) => (
            <li key={item.text}>
              <Link text={item.text} to={item.to} />
            </li>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Header;

const menuItems = [
  {
    text: 'Nossa missão',
    to: `${Routes.HOME}#mission`
  },
  {
    text: 'Estatísticas',
    to: `${Routes.HOME}#statistic`
  },
  {
    text: 'Dúvidas frequentes',
    to: `${Routes.HOME}#help`
  }
];
