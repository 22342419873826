import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyD4PcdP2-2Iu60sZ_TxgnviJ0xslHZKzA0',
  authDomain: 'b-one-saude.firebaseapp.com',
  projectId: 'b-one-saude',
  storageBucket: 'b-one-saude.appspot.com',
  messagingSenderId: '1013923835408',
  appId: '1:1013923835408:web:bdcff5dad59a997993e495'
};

const app = initializeApp(firebaseConfig);

export default app;
