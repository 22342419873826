import { Outlet, createBrowserRouter } from 'react-router-dom';

// Screens
import { HomePage, PrivacyPoliciesPage, DeleteAccount } from 'pages';

// Consts
import { Routes } from 'consts';
import { ReactElement } from 'react';

export const mainRouter = (complement: {
  header: ReactElement;
  footer: ReactElement;
}) =>
  createBrowserRouter([
    {
      element: (
        <>
          {complement.header}
          <Outlet />
          {complement.footer}
        </>
      ),
      children: [
        {
          path: Routes.HOME,
          element: <HomePage />
        },
        {
          path: Routes.PRIVACY_POLICIES,
          element: <PrivacyPoliciesPage />
        },
        {
          path: Routes.DELETE_ACCOUNT,
          element: <DeleteAccount />
        }
      ]
    }
  ]);
